

.chatclient-wrapper {
  float: left;
}

.chatclient-wrapper.self {
  float: right;
}

.chatclient--textarea-boxx {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
  padding: 20px;
  border-radius: 15px;
}

.chatclient--textarea {
  width: 100%;
  height: 90%;
  max-height: 530px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* width */
.chatclient--textarea::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.chatclient--textarea::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.chatclient--textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.chatclient--textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chatclient-wrapper.chatbot {
  border-radius: 10px;
  border: 0;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}

.chatclient-wrapper.chatbot img {
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
}

.roomdetails-wrapper .chatclient--textarea + * {
  margin-top: auto;
  display: flex;
  zoom: normal;
  border: 0;
  outline: none;
}
.roomdetails-wrapper .chatclient--textarea + * .ant-input {
  background: transparent;
  border: 1px solid #0983dc;
  width: 100% !important;
  height: 45px;
  border-radius: 10px 0 0 10px;
  color: white;
}
.roomdetails-wrapper .chatclient--textarea + * .ant-btn {
  background-color: #35b1e4 !important;
  border-color: #35b1e4 !important;
  border-radius: 10px;
  height: 50px;
  width: 60px;
  margin-left: 15px;
}
.fas.fa-paper-plane{
  color : #fff;
}
.app .ant-input-group.ant-input-group-compact .btn{
  width: 50px;
  border-radius: 5px;
}
.app .ant-input-group.ant-input-group-compact input {
  width: 100% !important;
  margin-right: 10px;
}
.chatclient--message {
  width: 100%;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 10px 6px -6px #777;
  overflow-wrap: break-word;
  padding: 5px;
  margin: 10px 0;
  background-color: #13c2c2;
  color: #fff;
}

.chatclient--message.self {
  background-color: #35b1e4;
  float: right;
  clear: both;
}

.chatclient--message::after {
	bottom: 100%;
	left: 5%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	border-bottom-color: #13c2c2;
	border-width: 6px;
}

.chatclient--message.self::after {
  left: 87%;
  top: -11.5px;
	border-color: transparent;
	border-bottom-color: #35b1e4;
}

.chatclient--user {
  display: inline-block;
  border-radius: 5px;
  background-color: #fff;
  color: #8c8c8c;
  line-height: normal;
  padding: 5px;
}

.chatclient-user.chatbot {
  color: #2f9ed6;
  font-size: 16px;
}
.chatclient-wrapper.chatbot span {
  word-break: break-word;
}

.chatclient--user.self {
  float: right;
}

.chatclient--username {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin-right: 5px;
  word-break: break-all;
}
.chatclient-wrapper.chatbot p {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.chatclient--timestamp {
  font-size: 12px;
}


.app {
}
.ant-card-bordered {
  border: 0;
  background: transparent;
  border-radius: 0;
}
.ant-card-head {
  background: transparent;
  min-height: 100%;
  margin-bottom: 0;
  border-bottom: 0;
  border-radius: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.ant-card-body {
  padding: 0;
}
.ant-alert-info {
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 15px;
  font-weight: 700;
  color: #2f9ed6;
}


