@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
body {
  font-family: 'Poppins', serif;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
}

@media (max-width: 1420px) {
  .app .ant-card {
    /* flex: 0 0 calc(100% - 200px);
    max-width:calc(100% - 200px); */
  }
  .app {
    flex-wrap: wrap;
  }
  .app .roomdetails--mid-pane {
    /* max-width: calc(200px + 30px); */
    width: 100%;
  }
  .app .chatclient--textarea {
    max-width: 100%;
    width: 100%;
  }
  .app .ant-input-group.ant-input-group-compact {
    display: inline-flex;
  }
  .app .ant-input-group.ant-input-group-compact input {
    width: 100% !important;
  }
  .app .roomdetails-wrapper {
    flex-wrap: wrap;
  }
}
@media (max-width: 1300px) {
  .app .roomdetails--mid-pane {
    /* max-width: calc(100% - 530px); */
    width: 100%;
    margin-right: 0px;
  }

  .chatclient--textarea-boxx {
    width: 100%;
    padding-top: 30px;
  }
  .app .menu-wrapper {
    position: fixed;
    width: 100%;
    overflow-y: auto;
    height: 100%;
  }
  .app .ant-card {
    margin-left: auto;
  }
}

@media (max-width: 1200px) {
  .app {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .app .roomdetails--mid-pane {
    max-width: 100%;
    width: 100%;
    margin-right: 0px;
    margin-top: 30px;
  }
}

@media (max-width: 1300px) {
  .app .ant-card {
    /* max-width: 100% !important;
    flex-basis: 100% !important; */
  }
}
