@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

@font-face {
  font-family: 'PoetsenOne';
  src: local('PoetsenOne'), url('./fonts/PoetsenOne-Regular.ttf') format('ttf');
}

:global(body) {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}
html,
body {
  min-height: 100vh;
  padding: 0;
  position: relative;
  font: 14px 'Nunito', sans-serif;
}
/* .cta:before{
  background: url(assets/img/game_title.png) fixed center 25px;
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
} */

.playGame__inner {
  position: relative;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.chessLogo {
  max-width: 100%;
  height: 53vh;
  margin: auto;
  display: block;
  object-fit: cover;
}
.playGame__inner1 {
  margin: auto;
}
.connector-container {
  margin-left: 15px;
}
.playStartButton {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
}

.image-container {
  position: relative;
}
.startButton {
  width: 140px;
  height: 48px;
  display: inline-block;
  vertical-align: middle;
  background: url(assets/img/start_button.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0;
}

ol,
ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: transparent;
  border: 1px solid transparent;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 48px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 48px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game-info {
  margin-left: 20px;
}

.icons-attribution,
.game-status,
.fallen-soldier-block {
  margin-top: 20px;
  min-height: 50px;
}

/*Board color scheme Wheat from http://omgchess.blogspot.com/2015/09/chess-board-color-schemes.html*/
.dark-square {
  background-color: RGB(187, 190, 100);
}

.light-square {
  background-color: RGB(234, 240, 206);
}

#player-turn-box {
  width: 32px;
  height: 32px;
  border: 1px solid #000;
  margin-bottom: 10px;
  display: inline-block;
}

h3 {
  margin-bottom: 5px;
}

.back-btn {
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.back-btn:hover {
  filter: drop-shadow(2px 4px 6px black);
}
.room {
  overflow: hidden;
}
.room-me {
  position: relative;
  padding: 0;
  /* background: url(assets/img/plan_bg.png) no-repeat;
  background-size: 100%; */
}
.room-me-inner {
  position: relative;
  margin-top: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}
.room-me-inner > .row + * {
  margin-top: 30px;
}

.room-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: auto;
  max-width: 1400px;
}

.room-cta-game {
  position: relative;
  margin: 50px 20px;
  background: #fff;
  border-radius: 20px;
  padding-top: calc(100% - 40px);
}

.room-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.room-title {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
}
.room-title span {
  font-size: 13px;
  color: #2e9ed7;
  margin-left: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 1028px) {
  .room-list {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .room-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .room-list {
    grid-template-columns: 1fr 1fr;
  }
  .room-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .room-list {
    grid-template-columns: 1fr;
  }
}
.room-cta-free {
  position: absolute;
  left: 15%;
  background: url(assets/img/bg_blue.png) no-repeat center;
  height: 85px;
  width: 67%;
  background-size: contain !important;
  bottom: -33px;
}

.room-cta-paid {
  position: absolute;
  left: 15%;
  background: url(assets/img/bg_green.png) no-repeat center;
  height: 85px;
  width: 67%;
  background-size: contain !important;
  bottom: -33px;
}

.room-txt {
  font-size: 30px;
  font-weight: bold;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.room-custom-txt {
  font-size: 20px;
}

.backToHomeBtn {
  min-width: 102px;
  height: 27px;
  border-radius: 13.5px;
  background: #fff;
  font-family: Poppins,serif;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #000b10;
  border: 1px solid #fff;
  margin: auto;
  padding: 0 10px;
}

.back-to-home {
  text-transform: uppercase;
}
.status-text {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.status-modal .ant-modal-content {
  border-radius: 15px;
  border: 2px solid #41b5e4;
}
.ant-modal-header {
  background-color: #0983dc !important;
}
.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  margin-top: 20px;
  gap: 10px;
}

.ant-modal-title {
  font-family: 'Poppins', serif;
  color: #ffffff !important;
}
.ant-modal-content {
  border-radius: 10px !important;
  background-color: #0983dc !important;
  font-family: 'Poppins', serif;
  color: #ffffff;
}
.loader-img {
  height: 50px;
}
.status-heading {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}
.back-to-home {
  font-size: 18px !important;
  height: 55px !important;
  border-radius: 8px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.back-to-home.black {
  font-size: 18px !important;
  height: 55px !important;
  border-radius: 8px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  background-color: #141d21 !important;
  border-color: #141d21 !important;
}
.back-to-home.claim {
  font-size: 18px !important;
  height: 55px !important;
  border-radius: 8px !important;
  padding: 0 15px !important;
}
.gRJQId {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(239, 244, 245);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 0px inset;
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  padding-left: 40px;
  padding-right: 8px;
  position: relative;
}
.evHBTT {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(250, 249, 250);
  border-color: #0b5ed7;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  height: 40px;
  -webkit-box-pack: center;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: -4px;
  width: 40px;
  z-index: 102;
}
.ACFFk {
  align-self: center;
  flex-shrink: 0;
}
.cglPKf {
  color: rgb(40, 13, 95);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.container {
  position: relative;
  z-index: 1000;
}
@media (max-width: 810px) {
  .cta img {
    padding: 100px;
  }
}
@media (max-width: 772px) {
  .cta:before {
    padding: 50px;
  }
}
@media (max-width: 772px) {
  .cta2 {
    background-size: 300px;
    /* width : 270px !important; */
    /* position: center; */
    /* width: 373px; */
    /* height: 107px; */
    background-size: contain;
  }
}
@media (max-width: 772px) {
  .cta:before {
    background: url(assets/img/game_title.png) fixed center 25px;
    background-size: 300px;
    content: '';
    background-position: 50% 30%;
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    padding: 100px;
  }
}
@media (max-width: 772px) {
  .container {
    margin-top: 9%;
  }
  .cta img {
    padding: 50px;
  }
}
@media only screen and (min-width: 576px) {
  .cglPKf {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
}
@media (min-width: 375px) and (max-width: 550px) {
  .cta img {
    padding: 40px;
  }

  .cta {
    position: absolute;
    top: 10.5%;

    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 772px) {
  .container {
    margin-top: 0%;
  }
  .cta img {
    padding: 0px;
  }
}

/* shoaib code */
@media only screen and (max-width: 768px) {
  .home-container {
    margin-top: 0;
  }
  .connector-container {
    margin-bottom: 15px;
  }
  .room-me-inner .row {
    justify-content: center;
  }
  .room-me {
    background-size: 100% 100%;
  }
  .room {
    overflow: scroll;
  }
}

@keyframes top-clip {
  0% {
  }
  50% {
    transform: translateY(21px);
  }
  100% {
    transform: translateY(21px);
  }
}

@keyframes bottom-sand-path {
  0% {
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bottom-sand-g {
  0% {
  }
  85% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-9px);
  }
}

@keyframes hourglass-rotation {
  50% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(180deg);
  }
}

#top-sand #top-clip-path rect,
#bottom-sand path,
#bottom-sand g,
.sandwatch-loader svg {
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.sandwatch-loader svg {
  width: 150px;
  height: 150px;
}
.sandwatch-loader .searchImage {
  height: 150px;
}
#top-sand #top-clip-path rect {
  animation-name: top-clip;
}

#bottom-sand path {
  transform: translateY(21px);
  animation-name: bottom-sand-path;
}

#bottom-sand g {
  animation-name: bottom-sand-g;
}

.sandwatch-loader svg {
  animation-name: hourglass-rotation;
}
.serch-player-contain {
  padding: 0 10%;
  position: relative;
  width: 100%;
}

.search-loader {
  /* background: #ffd8b6; */
  /* height: 100vh; */
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 40px 0;
  background-position-y: bottom;
  position: relative;
}

section.search-loader:before {
  content: '';
  position: absolute;
  /* background: url(assets/img/bg.jpg) repeat left top/60%; */
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  /* background-attachment: fixed; */
}
.main-player-loader {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 60px 90px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  /* border: 4px solid #f7ba87; */
}
.main-player-loader .right-form-bx {
  background: #b6e2ef;
  padding: 20px 30px;
  border-left: 2px solid #b6e2ef;
}
.player-serch-form label {
  font-size: 18px;
  color: #6a543d;
}
.player-serch-form .form-control {
  border: 2px solid #f3f3f3;
  background-color: #f3f3f3 !important;
  color: #616161;
  font-size: 16px;
  min-height: 54px;
  border-radius: 8px;
}
.player-serch-form {
  padding-bottom: 10px;
}

.btn-field .btn-list li {
  padding-bottom: 20px;
  width: 48%;
  list-style: none;
}
.btn-field .btn-list {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.btn-field .btn-list li .btn {
  background: #141d21;
  width: 100%;
  border: none;
  /* box-shadow: 0 4px 0 0 #537133, 0 0px 3px 0px rgba(0, 0, 0, 0.2); */
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  min-height: 46px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Nunito', sans-serif;
}
.btn-field .btn-list li .btn.btn-grey {
  background: #36b1e3;
  /* box-shadow: 0 4px 0 0 #6d6965, 0 0px 3px 0px rgba(0, 0, 0, 0.2); */
}

.btn-field .btn-list li .btn.btn-grey:hover {
  background: #36b1e3;
}

.btn-field .btn-list li .btn:hover {
  background: #141d21;
}
.sandwatch-loader {
  margin: 0 auto;
  text-align: center;
}
.player-searching-bx h4 {
  text-align: center;
  font-size: 40px;
  color: #36b1e3;
  font-weight: 700;
  padding-top: 50px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-family: 'PoetsenOne';
}
.main-player-loader .right-form-bx .serch-player-main {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
  height: 450px;
}
.main-player-loader .right-form-bx .serch-player-main .form-filed-up {
  flex: 0 0 100%;
  max-width: 100%;
  align-self: center;
}
.main-player-loader .right-form-bx .serch-player-main .btn-field {
  flex: 0 0 100%;
  max-width: 100%;
  align-self: flex-end;
  padding-bottom: 10px;
}
.main-player-loader .left-card {
  /* height: 100%; */
  margin-top: 30px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
}
.invite-button-container {
  margin-right: 10px;
  margin-left: 10px;
  /* position: absolute;
    left: 20px;
    right: 0; */
}
.outline-btn {
  color: #3db4e4 !important;
  border-color: #3db4e4 !important;
  /* border-color: #3db4e4 !important;
  border-width: 2px !important;
  border-radius: 7px !important; */
  font-family: 'Nunito', sans-serif;
}
.outline-btn:hover,
.btn-outline-secondary:active {
  background-color: #3db4e4 !important;
  border-color: #3db4e4 !important;
  color: #fff !important;
}
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgb(61 180 228 / 50%) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #fff !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #1890ff !important;
  border-top: 0 !important;
  border-left: 0 !important;
}
.checkbox-text {
  color: #59b1dd;
  text-transform: uppercase;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.checkbox-container {
  border: 2px solid #4caada;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.ant-checkbox-wrapper {
  align-items: center !important;
  display: flex !important;
}
.counter {
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
  background: #f3f3f3;
}
.time {
  font-size: 18px;
  font-weight: bolder;
}
.chatclient-joinroom {
  font-weight: bold;
  color: #353535;
}
.balance-text {
  color: #3f3f3f;
  font-weight: 600;
  margin-bottom: 0;
}
.balance {
  color: #33a1d8;
  font-weight: bolder;
  font-size: 20px;
  position: relative;
}
.claim-balnace-container {
  background: #fff;
  padding: 12px;
  border-radius: 15px;
  height: 90px;
  -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
  width: 350px;
  position: relative;
}
.account-container {
  position: relative;
  justify-content: flex-end;
  display: flex;
}
.claim-btn-container {
  position: absolute;
  bottom: -14px;
  left: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.claim-btn {
  width: 100px;
}
.claim-container {
  position: relative;
  height: 90vh;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-align: center; */
  /* align-items: flex-end; */
  /* -ms-flex-pack: center; */
  justify-content: space-around;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  align-items: flex-end;
}
.claim-container button {
  width: 200px;
}

.room-heading {
  color: #fff;
  font-family: 'Poppins', serif;
  font-size: 16px;
  font-weight: bold;
}
.room-info-body {
  min-height: 50px;
}
.room-info-body h1 {
  font-size: 20px;
}

.beta-description {
  font-size: 10px;
  color: white;
  text-align: center;
}

.reportBug {
  font-size: 10px;
  color: white;
  margin-top: 30px;
  text-decoration: underline;
  text-align: center;
}

.nowallet {
  color: white;
  font-size: 10px;
  text-align: center;
}

.learnConnect {
  color: white;
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(0, 38, 53);
  margin-top: 20px;
  font-size: 10px;
}
.room-info-back-btn {
  height: 50px !important;
  width: 120px !important;
  border-radius: 8px !important;
  border-color: #72777a !important;
  color: #72777a !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}
.room-info-create-btn {
  border-radius: 100px !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  background-color: transparent;
  font-size: 15px !important;
}
.room-ids-btn {
  color: #3ea2d8 !important;
  background-color: #f3f3f3 !important;
  border: none !important;
  border-radius: 8px !important;
  height: 50px !important;
  margin-bottom: 15px !important;
  font-weight: bolder !important;
  font-size: 13px !important;
  width: 250px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;


}
.room-ids-btn:hover {
  transform: scale(1.02);
  opacity: 0.8;
}
.room-ids-btn span {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: inherit !important;
}
.connect-wallet-model .ant-modal-header {
  background: #eef6ff;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  color: #3ea2d8 !important;
}
.connect-wallet-model .ant-modal-content {
  border-radius: 15px;
}
.connect-wallet-model .ant-modal-body {
  background-color: #efefef;
}
.connect-text {
  color: rgb(40, 13, 95);
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}
.Toaster__alert {
  background-color: white;
  overflow: hidden;
  max-width: 650px;
  position: relative;
  border-radius: 0.4rem;
  display: flex;
  padding: 1rem;
  padding-right: 48px;
  box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px, rgba(52, 58, 64, 0.1) 0px 6px 12px 0px,
    rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;
}

.Toaster__alert_text {
  box-sizing: border-box;
  font-family: 'Poppins', serif;
  color: rgb(33, 37, 41);
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0px;
}

.Toaster__alert_close {
  padding: 12px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  top: 7px;
  right: 4px;
  border-radius: 0.4rem;
  border: 0;
  -webkit-appearance: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.Toaster__alert_close:focus {
  box-shadow: rgba(52, 58, 64, 0.15) 0px 0px 0px 3px;
}

.Toaster__message-wrapper {
  padding: 8px;
}
.leader-board-container .heading {
  color: #fff;
  text-shadow: 2px 1px rgb(82 70 70 / 70%);
  font-size: 30px;
}
.board-container {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
}
.board-component-container .right-section {
  border-right: 1px solid #b7b1b1;
}
.board-info-heading {
  color: #0095cf;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab .badge.bg-secondary {
  background-color: #dedfdf !important;
  color: #8d8b8b;
  font-size: 0.95em !important;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-active .badge.bg-secondary {
  border: 1px solid #8d8b8b;
}
.main-player-loader .one {
  padding-right: 0;
  position: relative;
}
.main-player-loader .two {
  padding-left: 0;
}
.btn-field.footer .btn-list li {
  width: 100px;
}
.btn-field.footer .btn-list {
  justify-content: flex-end;
}
.ant-btn.room-ids-btn {
  width: 100% !important;
  text-align: left;
}
.ant-tabs-ink-bar {
  background-color: #a7abab !important;
}
@media only screen and (min-width: 1540px) {
  .serch-player-contain {
    /* padding: 0 15%;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto; */
  }
}

@media only screen and (max-width: 991px) {
  .serch-player-contain {
    /* padding: 0 20px;
    width: 100%; */
  }
  .main-player-loader .right-form-bx {
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .playGame__inner1 {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .main-player-loader .right-form-bx .serch-player-main {
    height: auto;
  }
  .main-player-loader .one {
    padding-right: 15px;
  }
  .player-searching-bx {
    padding: 20px;
  }

  .main-player-loader .right-form-bx {
    padding: 40px 20px 10px 20px;
  }

  .player-serch-form .form-control {
    min-height: 45px;
  }
  .player-searching-bx h4 {
    font-size: 30px;
  }

  .sandwatch-loader svg {
    width: 100px;
    height: 100px;
  }
  .gRJQId {
    width: 180px;
  }
  .cglPKf {
    width: 100%;
    padding: 0 10px;
    margin: 0;
  }
  .connector-container {
    margin-left: 0;
  }
}

/* styling for room details page */
@media only screen and (max-width: 470px) {
  .app {
    display: none;
  }
}

/* end styling for room details page */
@media only screen and (min-width: 1400px) {
  .search-loader.container,
  .leaderboard.container {
    max-width: 1600px;
  }
  .main-player-loader .right-form-bx .serch-player-main {
    height: 550px;
  }
  .leader-board-container .heading {
    font-size: 60px;
  }
  .badge {
    font-size: 1.5em !important;
  }
  .ant-table {
    font-size: 20px !important;
  }
  .ant-table-body {
    max-height: 250px !important;
  }
  .leader-board-container .board-badges p,
  .ant-tabs-content p {
    font-size: 25px !important;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab .badge.bg-secondary {
    font-size: 25px !important;
  }
  .playStartButton {
    bottom: 12px;
  }
  .startButton {
    width: 207px;
  }
}

@media only screen and (min-width: 2000px) {
  .playStartButton {
    bottom: 22px;
  }
}
.wallet__icon {
  height: 60px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}
.wallet__icon img,
.wallet__icon svg {
  height: 40px;
  width: auto;
  max-width: 100%;
  background: #fff;
  border-radius: 100px;
  padding: 10px;
}
.wallet_link {
  font-family: sans-serif;
  font-weight: 600;
  font-size: 18px;
}
.wallet__name {
  font-family: sans-serif;
  font-weight: 800;
  font-size: 18px;
}
.wallet-icon-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.wallet-name {
  font-family: 'Poppins', serif;
  font-size: 16px;
  font-weight: 700;
  margin-left: 15px;
  color: white;
}


.rewards-loader {
  width: 80px;
  visibility: hidden;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.visible-loader {
  visibility: visible;
}