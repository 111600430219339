.roomdetails-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
}

.game-status {
  position: relative;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.soundBtn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.soundBtn button {
  padding: 5px;
  width: 50px;
  height: 50px;
}
@media (max-width: 570px) {
  .ant-alert.ant-alert-info.ant-alert-no-icon.gamestatus {
    max-width: 100%;
  }
  .ant-alert.ant-alert-info.ant-alert-no-icon.gamestatus .ant-alert-message {
    white-space: pre-wrap;
  }
  /*.board-b72b1 {*/
  /*  width: max-content !important;*/
  /*}*/
  .board-b72b1 > div {
    height: 50px !important;
    display: flex !important;
  }
  .board-b72b1 > div > div {
    height: unset !important;
    width: unset !important;
    flex: 1 !important;
    display: flex !important;
  }
  .board-b72b1 > div > div:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .board-b72b1 > div > div[class*="clearfix"] {
    display: none !important;
  }
  .board-b72b1 > div > div > img {
    width: 35px !important;
    height: 35px !important;
    display: block;
    margin: auto;
  } 
}
.roomdetails--mid-pane {
  height: 75%;
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
  margin: 0;
  padding: 20px;
  border-radius: 15px;
}

.roomdetails--mid-pane-title {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  border-bottom: 0;
  text-align: center;
}

.roomdetails--mid-pane-users {
  height: calc(100% - 50px);
  overflow: auto;
}

.roomdetails--mid-pane-user {
  display: flex;
}
.roomdetails--mid-pane-user img {
  width: 30px;
  height: 30px;
}
.roomdetails--mid-pane-user span {
  width: calc(100% - 30px);
  padding-left: 10px;
  word-break: break-all;
  font-size: 15px;
  font-weight: 700;
  color: #35b1e4;
  line-height: 20px;
}