.loadingContainer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  visibility: hidden;
  transition: visibility 0s 0.65s;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1002;

  &.containerVisible {
    transition: visibility 0s;
    visibility: visible;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 220px;
  }
}